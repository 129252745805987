.main_parent_div_of_testimonial {
  padding: 80px 10px 10px 10px;
  position: relative;
}
.Testimonials_main_heading{
  padding: 40px;
}

.main_data_tesimonail {
  /* background: #b52929; */
  padding: 115px 70px 30px 70px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border-radius: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
/* image */
.testimonail_image_div {
  position: absolute;
  top: 0px;
  left: 3%;
  border-radius: 30px;
}
.testimonial_image {
  width: 20%;
  border-radius: 20px;
}
.desing_testimonial_image {
  width: 90%;
}
.desing_testimonial_image_div {
  width: 70%;
}


/* text */
.testimonail_review_text_div {
  border-bottom: 4px solid black;
  width: 50%;
}
.testimonail_review_text {
  color: black;
  font-size: 50px;
  font-weight: bold;
  font-family: var(--poppins-font-family);
}

.testimonail_review_message {
  background: var(--main-red-color);
  padding: 20px 50px;
  transform: skew(20deg);
  color: var(--main-white-color);
  font-size: 18px;
  font-family: var(--inter-font-family);
  position: relative;
}
.message_testimonial_first_image_div {
  position: absolute;
  transform: skew(-20deg);
  left: 0;
  top: -50px;
}
.message_testimonial_last_image_div {
  position: absolute;
  transform: skew(-20deg);
  bottom: -30px;
  right: 0;
}
.message_testimonial_image {
  width: 70px;
}
.testimonail_review_message .text {
  transform: skew(-20deg);
}
.testimonail_review_user_name {
  font-size: 20px;
  font-weight: bold;
}


@media (max-width:1200px) {
  .main_parent_div_of_testimonial {
    padding: 80px 10px 10px 10px;
  }
  
  .main_data_tesimonail {
    padding: 110px 55px 30px 55px;
    gap: 18px;
  }
  /* image */
  .testimonail_image_div {
    left: 3%;
  }
  .testimonial_image {
    width: 30%;
    border-radius: 20px;
  }
  .desing_testimonial_image {
    width: 100%;
  }
  .desing_testimonial_image_div {
    width: 80%;
  }
  
  
  /* text */
  .testimonail_review_text_div {
    border-bottom: 4px solid black;
    width: 50%;
  }
  .testimonail_review_text {
    color: black;
    font-size: 40px;
  }
  
  .testimonail_review_message {
    padding: 20px 30px;
    transform: skew(20deg);
    font-size: 17px;
  }
  .message_testimonial_first_image_div {
    left: 0;
    top: -50px;
  }
  .message_testimonial_last_image_div {
    bottom: -30px;
    right: 0;
  }
  .message_testimonial_image {
    width: 70px;
  }
  .testimonail_review_message .text {
    transform: skew(-20deg);
  }
  .testimonail_review_user_name {
    font-size: 20px;
    font-weight: bold;
  }
  
}


@media (max-width:800px) {
  .main_parent_div_of_testimonial {
    padding: 80px 10px 10px 10px;
  }
  
  .main_data_tesimonail {
    padding: 110px 50px 20px 50px;
    gap: 16px;
  }
  /* image */
  .testimonail_image_div {
    left: 3%;
  }
  .testimonial_image {
    width: 30%;
    border-radius: 20px;
  }
  .desing_testimonial_image {
    width: 100%;
  }
  .desing_testimonial_image_div {
    width: 80%;
  }
  /* text */
  .testimonail_review_text_div {
    border-bottom: 4px solid black;
    width: 50%;
  }
  .testimonail_review_text {
    color: black;
    font-size: 35px;
  }
  
  .testimonail_review_message {
    padding: 15px 20px;
    transform: skew(20deg);
    font-size: 16px;
  }
  .message_testimonial_first_image_div {
    left: 0;
    top: -50px;
  }
  .message_testimonial_last_image_div {
    bottom: -30px;
    right: 0;
  }
  .message_testimonial_image {
    width: 70px;
  }
  .testimonail_review_message .text {
    transform: skew(-20deg);
  }
  .testimonail_review_user_name {
    font-size: 20px;
    font-weight: bold;
  }
}


@media (max-width:500px) {
  .main_parent_div_of_testimonial {
    padding: 45px 5px 5px 5px;
  }
  
  .main_data_tesimonail {
    padding: 110px 20px 20px 20px;
    gap: 16px;
  }
  /* image */
  .testimonail_image_div {
    left: 1%;
  }
  .testimonial_image {
    width: 30%;
    border-radius: 20px;
  }
  .desing_testimonial_image {
    width: 100%;
  }
  .desing_testimonial_image_div {
    width: 80%;
  }
  /* text */
  .testimonail_review_text_div {
    border-bottom: 4px solid black;
    width: 50%;
  }
  .testimonail_review_text {
    color: black;
    font-size: 30px;
  }
  
  .testimonail_review_message {
    padding: 15px 15px;
    transform: skew(10deg);
    font-size: 14px;
  }
  .message_testimonial_first_image_div {
    left: 0;
    top: -40px;
  }
  .message_testimonial_last_image_div {
    bottom: -25px;
    right: 0;
  }
  .message_testimonial_image {
    width: 60px;
  }
  .testimonail_review_message .text {
    transform: skew(-10deg);
  }
  .testimonail_review_user_name {
    font-size: 20px;
    font-weight: bold;
  }
}