.main_bite_intotext{
    padding: 20px 100px ;
}
.bite_intotext_section{
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 1px;
    box-shadow: 0px 3px 0px  rgba(0, 0, 0, 0.146);
    padding: 20px 10px 15px 10px;
}
.text_list_bite{
    font-weight: bolder;
    color: red;
}

@media (max-width:768px) {
    .main_bite_intotext{
        padding: 20px 40px ;
    }
}

@media (max-width:500px) {
    .bite_intotext_section{
        font-size: 23px;
    }
    .main_bite_intotext{
        padding: 20px 50px ;
    }
 
}