.loacte_contant_div{
    display: flex;
    gap: 20px;
}
.locate_text{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    font-size: 50px;
    letter-spacing: 2px;
    font-family:  var(--Inria--Sans--font-family);
}
.locate_ifrem_div{
    width: 100%;
}
.contact_us_google_ifream{
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    height: 380px;
}

@media (max-width:1024px) {
    .contact_us_google_ifream{
        width: 95%;
        height: 380px;
    }
    
}
@media (max-width:500px) {
    .loacte_contant_div{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
    }
}