.main_right_side_proudcts_top_parent_div {
  height: 100vh;
  font-family: var(--Inria--Sans--font-family);
}

.main_product_images_parant_div {
  display: flex;
  padding: 10% 0 0 5%;
  height: 100%;
}

.location_icon_product_text_page {
  position: relative;
}
.location_price {
  position: absolute;
  top: 15px;
  font-size: 20px;
  left: 25px;
}

.product_images_main_div {
  width: 100%;
  display: flex;
}

.main_orange_center_image_div {
  position: relative;
}

.left_product_image {
  position: absolute;
  right: 0px;
  top: -10vh;
}

.right_product_image {
  position: absolute;
  left: -5vw;
  bottom: 16vh;
}

.main_orange_center_image {
  width: 90%;
}

.right_side_top_product_image {
  width: 80%;
}

.main_product_text_div_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10vh;
}

.image_text_products_red_color {
  background: red;
  width: 70%;
  color: white;
  font-size: 3vw;
  text-align: center;
}

.image_text_products_red_color_bottom_section {
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  width: 70%;
  font-size: 3vw;
}

.location_icon_product_text_page {
  width: 5vw;
}

@media (max-width: 1025px) {
  .location_price {
    position: absolute;
    top: 6px;
    font-size: 18px;
    left: 13px;
  }
  .left_product_image {
    position: absolute;
    right: -10vh;
    top: -10vh;
  }

  .right_product_image {
    position: absolute;
    left: -5vw;
    bottom: 24vh;
  }
  .main_orange_center_image {
    width: 100%;
  }

  .right_side_top_product_image {
    width: 70%;
  }
  .main_product_text_div_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30vh;
  }
}

/* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
  .location_price {
    position: absolute;
    top: 6px;
    font-size: 15px;
    left: 9px;
  }
  .main_product_images_parant_div {
    padding: 20% 0 0 5%;
  }

  .image_text_products_red_color,
  .image_text_products_red_color_bottom_section {
    width: 90%;
    font-size: 4vw;
    justify-content: center;
    gap: 2px;
  }
  .image_text_products_red_color_bottom_section {
    gap: 15px;
  }
  .main_right_side_proudcts_top_parent_div {
    height: 80vh;
  }
  .main_orange_center_image {
    width: 100%;
  }

  .right_side_top_product_image {
    width: 40%;
  }
  .left_product_image {
    position: absolute;
    right: -10vh;
    top: -10vh;
  }

  .right_product_image {
    position: absolute;
    left: -5vw;
    bottom: 20vh;
  }
}

@media screen and (max-width: 500px) {
  .main_right_side_proudcts_top_parent_div {
    height: 50vh;
  }

  .main_product_images_parant_div {
    padding: 10% 0 0 0%;
  }

  .right_product_image {
    position: absolute;
    left: -5vw;
    top: 90px;
  }

  .main_product_text_div_section {
    padding-bottom: 40vh;
  }

  .image_text_products_red_color,
  .image_text_products_red_color_bottom_section {
    width: 100%;
    font-size: 6vw;
  }

  .image_text_products_red_color_bottom_section {
    gap: 10px;
  }

  .location_icon_product_text_page {
    width: 10vw;
  }

  .main_orange_center_image {
    width: 100%;
  }

  .right_side_top_product_image {
    width: 40%;
  }
  .left_product_image {
    display: none;
  }
}
