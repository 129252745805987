@media (max-width: 500px) {
    .main_header_for_small_nav {
      position: relative;
    }
    .navbar_for_big_screen {
      display: none;
    }
  }
  @media (min-width: 501px) {
    .main_header_for_small_nav{
      display: none;
    }
  }
  
  