.main_navbar_div {
  background: var(--main-white-color);
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  padding: 10px;
}
.main_navbar_div_ul {
  display: flex;
  /* justify-content: space-around; */
  width: 100%;
  align-items: center;
}

.main_navbar_li {
  list-style: none;
  color: var(--main-red-color);
  font-weight: 600;
  width: 150px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; 
  transition: all 0.7s ease;
  border-radius: 40px;

}
.main_navbar_li:hover {
  background: linear-gradient(
    358deg,
    var(--main-white-color),
    var(--main-red-color),
    var(--main-red-color)
  );
  color: var(--main-white-color);
  scale: 1.1;
}
.navbar_logo {
  width: 80%;
}

.active .main_navbar_li {
 background: linear-gradient(
    358deg,
    var(--main-white-color),
    var(--main-red-color),
    var(--main-red-color)
  );
  color: var(--main-white-color);
}
