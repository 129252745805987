.main_Our_story_div{
}
.main_Our_story_div_hading{
    font-size: 60px;
    color: var(--main-red-color);
    font-weight: bold;
    font-family: var(--akshar-font-family);
}
.main_Our_story_div_text{
    background: rgb(220, 220, 23);
    padding: 10px 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.main_Our_story_div_message{
    font-weight: 600;
    word-spacing: 2px;
    letter-spacing: 2px;
    font-size: 24px;
    font-family: var(--inter-font-family);
}
.know_more_button{
    padding: 12px 25px;
    border: 2px solid var(--main-red-color);
    color: var(--main-red-color);
    background: var(--main-white-color);
    width: 200px;
    cursor: pointer;
    font-family: var(--inter-font-family);

}
.know_more_button:hover{
    border: 2px solid var(--main-white-color);
    color: var(--main-white-color);
    background: var(--main-red-color);
}
@media (max-width:1030px) {
    .main_Our_story_div_hading{
        font-size: 50px;
    }
    .main_Our_story_div_text{
        padding: 10px 80px;
        gap: 20px;
    }
    .main_Our_story_div_message{
        font-size: 22px;
    }
}
@media (max-width:800px) {
    .main_Our_story_div_hading{
        font-size: 50px;
    }
    .main_Our_story_div_text{
        padding: 10px 60px;
        gap: 20px;
        }
        .main_Our_story_div_message{
            font-size: 20px;
        }
}
@media (max-width:500px) {
    .main_Our_story_div_hading{
        font-size: 50px;
    }
    .main_Our_story_div_text{
        padding: 10px 20px;
        gap: 20px;
    }
    .main_Our_story_div_message{
        font-weight: 600;
        word-spacing: 0px;
        letter-spacing: 0px;
        font-size: 17px;

    }
}