body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* goggle font */
/* akshr */
@import url("https://fonts.googleapis.com/css2?family=Akshar:wght@300..700&display=swap");
/* inter */
@import url("https://fonts.googleapis.com/css2?family=Akshar:wght@300..700&family=Inter:wght@100..900&display=swap");
/* popins */
@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300..700&family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Simonetta */
@import url('https://fonts.googleapis.com/css2?family=Simonetta:ital,wght@0,400;0,900;1,400;1,900&display=swap');
/* Alegreya */
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Simonetta:ital,wght@0,400;0,900;1,400;1,900&display=swap');
/* Inria Sans */
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Simonetta:ital,wght@0,400;0,900;1,400;1,900&display=swap');

:root {
  --main-red-color: red;
  --main-white-color: whitesmoke;
  --akshar-font-family: "Akshar", sans-serif;
  --inter-font-family: "Inter", sans-serif;
  --simonetta-font-family: "Simonetta", sans-serif;
  --alegreya-font-family:"Alegreya", serif;
  --Inria--Sans--font-family: "Inria Sans", sans-serif;
  --poppins-font-family: "Poppins", sans-serif;
}
/* main padding */
.main_padding {
  padding: 30px 100px;
}
/* main hading */
.main_heading {
  color: var(--main-red-color);
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  /* padding: 40px; */
  font-family: var(--akshar-font-family);
}
/* for header */
.css-for-link-tag-for-header{
  text-decoration: none; 
}
/* for footer */
.css-for-link-tag{
  text-decoration: none; 
  color:var(--main-white-color);
 }


 @media (max-width:1030px) {
  .main_padding {
    padding: 25px 50px;
  }
  .main_heading {
    font-size: 40px;
    padding: 30px;
  }
 }
 @media (max-width:900px) {
  .main_padding {
    padding: 22px 30px;
  }
  .main_heading {
    font-size: 33px;
    padding: 25px;
  }
 }
 @media (max-width:800px) {
  .main_padding {
    padding: 20px 20px;
  }
  .main_heading {
    font-size: 28px;
    padding: 28px;
  }
 }
 @media (max-width:500px) {
  .main_padding {
    padding: 15px 10px;
  }
  .main_heading {
    font-size: 28px;
    padding: 28px;
  }
 }

 
.slide-in{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color:var(--main-red-color);
  transform-origin: bottom;
}

.slide-out{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color:black;
  transform-origin: top;
}