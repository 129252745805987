.main_where_find{
    margin-top: 50px;
}
.main_where_find_contant{
    padding: 30px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    background: transparent;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 20px;
}

/* heading */
.where_find_red_heading{
    color: var(--main-red-color);
    font-weight: bold;
    font-size: 40px;
    font-family: var(--alegreya-font-family);
}

/* list */
.where_find_list_main_div{
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 24px;
    font-family: var(--Inria--Sans--font-family);
}








@media (max-width:500px) {
    
}